import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { objectMap } from "sutro-common/object-operations";

const screens = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1400px",
};

const breakpoints = objectMap(screens, ([, value]) => Number.parseInt(value));

interface WindowSize {
  height: number | undefined;
  width: number | undefined;
}

const WindowSizeContext = createContext<WindowSize | undefined>(undefined);

export const WindowSizeProvider = ({ children }: { children: ReactNode }) => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <WindowSizeContext.Provider value={windowSize}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export const useWindowSize = () => {
  const context = useContext(WindowSizeContext);

  if (!context) {
    throw new Error("useWindowSize must be used within a WindowSizeProvider");
  }

  return context;
};

const useBreakpoints = () => {
  const { width } = useWindowSize();

  const bp = useMemo(() => {
    return objectMap(
      breakpoints,
      ([, value]) => width === undefined || width > value
    );
  }, [width]);

  return bp;
};

export default useBreakpoints;
